#gallery {
    .project {
        margin-bottom: 30px;
        cursor: pointer;
        background-color: $light-grey;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        overflow: hidden;
        transition: 0.3s all;
        @include sm {
            max-height: none;
        }
        &:hover .pro-img img {
            filter: grayscale(0%);
        }
        .pro-img {
            width: 100%;
            height: 250px;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                height: auto;
                filter: grayscale(100%);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .info {
            background-color: rgba($color: #000, $alpha: 0.5);
            position: absolute;
            bottom: 0%;
            text-align: justify;
            padding: 0 20px;
            height: 0px;
            color: $white;
            transition: 0.3s all;
            width: 100%;
            h5 {
                font-size: 16px;
                margin-top: 20px;
                text-transform: uppercase;
            }
            p {
                opacity: 0;
            }
        }

        &:hover .info {
            height: 50px;
        }
        // &:hover .info p {
        //     opacity: 1;
        // }
    }
}
