.awards-wrapper {
    padding: 80px 0;
    @include sm {
        padding: 20px 0;
    }

    .award {
        border-left: 1px solid #000 !important;
        margin-bottom: 50px;
        min-height: 200px;
        display: flex;
        justify-content: start;
        align-items: center;
        // padding: 10px;

        .award-content {
            padding: 10px;
            padding-left: 20px;
            transition: 0.3s all;
            // min-height: 200px;
            // &:hover {
            //     background-color: $light-grey;
            // }
        }
        .awards-date {
            padding-bottom: 5px;
            border-bottom: #000;
        }
        .awards-title {
            font-family: "Oswald", sans-serif;
            padding-bottom: 5px;
            font-size: 22px;
        }
        .awards-subtitle {
            padding-bottom: 5px;
            font-size: 14px;
        }
    }
}
