.floating-scroll {
    position: fixed;
    top: 600px;
    right: 5%;
    z-index: 2000;
    background-color: $light-grey;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s all;
    @include xl {
        top: 850px;
    }
    cursor: pointer;
    &:hover {
        background-color: $black;
        color: $white;
    }
}
