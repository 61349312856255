// google font
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300&family=Open+Sans:wght@400;600;700&display=swap");

// colors settings
$white: #fff;
$dark: #141a25;
$blue: #3aa0ff;
$grey: #535353;
$black: #000;
$light-grey: #f0f0f0;

// scrollbar
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $white;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $blue;
}
// scrollbar end

// base settings
*,
html {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
}
body {
    font-family: "Open Sans", sans-serif;
    color: $black;
    background-color: $white;
    // background-image: url("../../images/background.webp");
    // background-position: center center;
    // background-repeat: repeat-y;
    // background-size: cover;
    background-attachment: fixed;
}

// font settings
h1 {
    font-size: 50px;
    font-weight: 300;
    font-family: "Oswald", sans-serif;
    @include xl {
        font-size: 70px;
    }
    @include sm {
        font-size: 40px;
    }
}
h2 {
    font-family: "Oswald", sans-serif;
    @extend h1;
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    @include sm {
        font-size: 30px;
    }
}
h3 {
    font-family: "Oswald", sans-serif;
    @extend h1;
    font-size: 20px;

    @include xl {
        font-size: 26px;
    }
    @include sm {
        font-size: 20px;
    }
}

h5 {
    font-weight: bold;
    font-style: 16px;
    font-weight: 400;
}

p {
    font-style: 14px !important;
    font-weight: 400;
}
a {
    font-size: 14px;
    font-weight: 400;
    color: $blue;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $black;
        text-decoration: none;
    }
}

section {
    padding-top: 100px;
}

section#home {
    padding-top: 0px;
}
section#testimonial {
    // padding: 0 75px;
    // padding-top: 50px;
    // @include xl {
    //     padding-top: 100px;
    // }
}

.sub-heading {
    color: #797979;
    font-size: 14px;
}

.thumb {
    border: none !important;
    border: 3px solid $dark !important;
}
.thumb.selected {
    border: 3px solid #fff !important;
}

.dim-background {
    img {
        width: 100vw;
        height: 100vh;
        z-index: -1;
    }
}
.item-black {
    // background-color: #3d3d3d !important;
    border: 1px solid $grey;
    color: $black !important;
}
.item-yellow {
    color: #000 !important;
    background-color: #ffcb0c !important;
}
.item-blue {
    border: 1px solid $grey;
    color: $black !important;
    background-color: transparent !important;
}

.item-padding {
    padding: 3px;
}
.text-display {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 30px;
}
.text-heading {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 22px;
    padding-bottom: 5px;
}
.text-subheading {
    font-family: "Oswald", sans-serif;
    font-weight: 300;
    font-size: 16px;
    padding-bottom: 5px;
}
.text-paragraph {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    padding-bottom: 5px;
}
.text-uppercase {
    text-transform: uppercase;
}
.text-lowercase {
    text-transform: lowercase;
}
.flex-box-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @include sm {
        flex-direction: column;
    }
}

.vertical-timeline--two-columns .vertical-timeline-element-icon {
    width: 45px !important;
    height: 45px !important;
    margin-top: 9px !important;
    margin-left: -22px !important;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    width: 30px !important;
    height: 30px !important;
    margin-left: -17px !important;
    margin-top: 15px !important;
    @include sm {
        margin-top: 13px !important;
        margin-left: 8px !important;
        width: 20px !important;
        height: 20px !important;
    }
}
