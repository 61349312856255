.metrics-counter-wrapper {
    padding: 30px 0;
    background-color: $light-grey;
    @include xl {
        padding: 50px 0;
    }
    .counter {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .head-info {
        font-size: 14px;
    }
    .head {
        font-size: 22px;
    }
}
