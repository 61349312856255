#sustainability {
    padding-top: 50px;
    .sustain {
        h3 {
            font-size: 30px;
        }
        p {
            text-align: justify;
        }
        .icon {
            min-height: 140px;
            max-height: 140px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: $light-grey;
        }
        .sustain-info {
            min-height: 140px;
            max-height: 140px;
            padding: 20px 30px;
            width: 100%;
            height: 100%;
            background-color: $light-grey;
            @include sm {
                max-height: none;
            }
        }
    }
}
