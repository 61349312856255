#team {
    .teams {
        margin-top: 0px;
        h2 {
            font-size: 26px;
        }
        h3 {
            text-transform: uppercase;
            font-size: 26px;
        }
        h5 {
            font-size: 16px;
        }
        .member {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: $black;
            height: 100;
            transition: 0.3s all;
            // background-color: $black;
            img {
                width: 100%;
                height: auto;
                opacity: 1;
                transition: 0.3s all;
                max-height: 356px;
                filter: grayscale(100%);
                @include sm {
                    max-width: 100%;
                    max-height: none;
                }
            }
            .member-info {
                position: absolute;
                z-index: 1;
                text-align: center;
                opacity: 1;
                transition: 0.3s all;
                background-color: rgba(0, 0, 0, 0.6);
                bottom: 0%;
                width: 100%;
                padding: 10px 0;
                height: 30px;
                min-height: auto;
                max-height: auto;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: $white;
                padding: 2px;
                h5 {
                    font-size: 16px;
                    // font-weight: bold;
                    margin: 0;
                    font-family: "Oswald", sans-serif;
                }
                p {
                    height: 0%;
                    font-size: 13px;
                    margin-bottom: 0;
                    opacity: 0 !important;
                }
            }
            &:hover .member-info {
                opacity: 1;
                height: 100%;
            }
            &:hover p {
                height: auto;
                opacity: 1 !important;
            }
        }
        .member.other-member {
            .member-info {
                height: 30px;
                padding: 2px;
            }
            p {
                opacity: 0 !important;
                height: 0%;
                color: $white;
            }
            h5 {
                color: $white;
                margin: 0;
            }
            &:hover .member-info {
                opacity: 1;
                height: 100%;
            }
            &:hover p {
                opacity: 1 !important;
                height: auto;
                margin-top: 5px;
            }
        }
        .eco-member {
            @extend .member;
            width: 100%;
            height: auto;
            min-height: 150px;
            // color: $white;
            background-color: $light-grey;
            .member-info {
                opacity: 1;
                background-color: transparent;
                height: 100%;
                padding: 20px;
                color: $black;
                p {
                    padding-top: 5px;
                    height: auto;
                    opacity: 1 !important;
                }
            }

            &:hover .member-info {
                opacity: 1;
                height: 100%;
            }
        }
        .co-founder {
            background-color: $light-grey;
            img {
                width: 100%;
                height: auto;
                filter: grayscale(100%);
                @include sm {
                    max-width: 100%;
                }
            }
            .co-founder-info {
                padding: 0 20px;
                text-align: justify;
                @include sm {
                    padding: 40px !important;
                }
            }
        }
    }

    img {
        @include sm {
            max-width: 100%;
            height: auto;
        }
    }
}
