#clients {
    .clients-wrapper {
        padding: 50px 0;
        .client {
            margin: 5px;
            padding: 10px;
            // border: 1px solid $black;
            transition: 0.3s all;
            cursor: pointer;
            box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.158);
            // &:hover {
            //     border: 1px solid $blue;
            //     // margin-top: -10px;
            // }
            img {
                width: 100%;
                height: auto;
                // filter: grayscale(100%);
            }
            // &:hover img {
            //     filter: grayscale(0%);
            // }
        }
    }
}
