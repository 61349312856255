.news {
    display: flex;
    @include sm {
        margin-top: 50px;
        display: none;
    }

    .news-item {
        min-height: 200px;
        max-height: 500px;
        background-color: $light-grey;
        cursor: pointer;
        @include sm {
            display: none;
        }
        .news-img {
            img {
                width: 100%;
                height: auto;
                // max-height: 130px;

                @include xl {
                    // max-height: 150px;
                }
            }
        }
        .news-content {
            min-height: 50px;
            margin-top: 10px;
            padding: 5px 15px;
            padding-bottom: 0 !important;

            @include xl {
                padding: 25px;
            }
            h5 {
                font-size: 14px;
                // font-weight: 600;
            }
            p {
                text-align: justify;
                font-size: 12px;
                font-weight: 400;
            }
        }
        .news-author {
            display: flex;
            justify-content: start;
            align-items: center;
            padding: 10px 15px;
            .avater {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                img {
                    width: 100%;
                    height: auto;
                    border-radius: 50%;
                }
            }
            .author {
                margin-left: 10px;
                h5,
                p {
                    margin: 0;
                    font-size: 14px;
                    font-weight: bold;
                }
                h5 {
                    text-transform: uppercase;
                }
                .role {
                    color: $blue;
                }
            }
        }
    }
}

#news {
    .news-item {
        // min-height: 300px;
        @include sm {
            padding-bottom: 20px;
            min-height: none;
        }
    }
    .news {
        @include sm {
            margin-top: 20px;
            display: block;
        }
    }
    .news-img {
        width: 100%;
        height: 310px;
        overflow: hidden;
        position: relative;
        // background-color: #3d3d3d;
        background-color: $black;
        @include xl {
            height: 420px;
        }
        @include sm {
            height: 345px;
        }
        img {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            // height: 100%;
            // max-height: 200p;
            // height: 500px;
            // max-width: 200px;
        }
    }
}

.news-item.featured {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .news-author {
        padding: 50px;
    }
    @include xl {
        min-height: 620px;
    }
}
