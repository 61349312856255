.full-project-view-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 1000000000;
    background-color: $white;
    @include sm {
        overflow-y: scroll;
    }
    .close-btn {
        color: $black;
        padding: 10px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 5%;
        right: 3%;
        z-index: 100000000;
        cursor: pointer;
        transition: 0.6s all;
        border: 1px solid $black;
        font-weight: bold;
        box-shadow: inset 0px 0 0 0 $black;
        &:hover {
            // background-color: $blue;
            box-shadow: inset 400px 0 0 0 $black;
            color: $white;
        }
        @include sm {
            top: 4% !important;
            bottom: auto;
        }
        @include xl {
            top: 10%;
            right: 5%;
        }
    }
    .items {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        @include sm {
            align-items: flex-end !important;
            // padding-bottom: 70px;
            width: 100%;
            height: 400px;
            overflow: hidden;
            position: relative;
            margin-top: 0px;
            // margin-top: 450px;
        }
        img {
            width: auto;
            height: auto;
            // max-width: 100%;
            // max-height: 100%;
            @include sm {
                width: 100%;
                height: auto;
                // filter: grayscale(100%);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
    .thumbs {
        bottom: 0% !important;
        left: 0% !important;
        position: absolute !important;
        z-index: 100000;
        background-color: $light-grey;
        width: 100%;
        min-height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            @include sm {
                max-height: 50px;
                // overflow: hidden;
            }
        }
        @include sm {
            max-height: 50px;
            overflow: hidden;
        }
    }
    .thumb .selected {
        border: 1px solid $black !important;
    }

    .carousel-status {
        top: 93% !important;
        left: 10% !important;
        z-index: 10000000;
        font-size: 20px !important;
        width: 100px !important;
        height: 100px !important;
        color: $black !important;
        box-shadow: none;
        text-shadow: none !important;
        @include sm {
            top: 10% !important;
        }
    }
    .carousel.carousel-slider .control-arrow {
        background: transparent !important;
    }
}

#home .full-project-view-wrapper {
    position: absolute;
}
#about .thumbs-wrapper {
    position: absolute;
    bottom: 0%;
    left: 0;
    z-index: 10000000000;
    .thumbs {
        overflow-x: scroll;
        .thumb {
            min-width: 100px;
        }
    }
}
.project-info-wrapper {
    background-color: $white;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
    h3 {
        font-size: 20px;
        // @include xl {
        //     font-size: 16px;
        // }
    }
    @include sm {
        margin-top: 0px;
        // margin-top: -580px;
        h3 {
            font-size: 14px;
        }
        h5 {
            font-size: 14px;
        }
        p {
            font-size: 13px;
        }
    }
    .project-info {
        text-align: justify;
    }
    .project-box {
        background-color: $light-grey;
        padding: 20px;
        margin: 20px 0;
    }
}
