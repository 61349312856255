#home {
    .section {
        margin: 0%;
        padding: 0%;
        width: 100%;
        height: 100vh;
        &::-webkit-scrollbar {
            width: 0 !important;
        }
    }
    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
    }
    .intro-gif {
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $black;
        position: fixed;
        z-index: 20000000000;
    }
    .intro {
        position: relative;
        // margin-top: 70px;
        .headline-Wrapper {
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .home_slider_wrapper {
            // position: absolute;
            // left: 0;
            // top: 0;
            margin-top: 25px;
            width: 100%;
            min-height: 85vh;
            max-height: 85vh;
            z-index: -100;
            overflow: hidden;
            @include xl {
                min-height: 800px;
            }
            img {
                // margin-top: -50px;
                min-height: 85vh;
                min-width: 100%;
                max-width: 100%;
                max-height: 100%;
                display: block; /* remove extra space below image */
                @include xl {
                    min-height: 800px;
                }
            }
        }
        .carousel .slider {
            width: 100%;
            min-height: 85vh;
            max-height: 85vh;
            @include xl {
                min-height: 800px;
            }
        }
        .control-dots {
            bottom: 5%;
            @include xl {
                bottom: 5%;
            }
        }
        .carousel.carousel-slider .control-arrow {
            width: 130px !important;
            background: rgba($color: #000, $alpha: 0.5);
            @include xl {
                width: 190px !important;
            }
        }
        .slide {
            padding: 0 2px;
        }

        .content {
            position: fixed;
            bottom: 0;
            // background: rgba(0, 0, 0, 0.5);
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 1;
            h1 {
                font-size: 50px;
            }
            button {
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                background-color: transparent !important;
                color: $white;
                transition: 0.3s all;
                &:hover {
                    transform: scale(1.2);
                }
            }

            @keyframes slideRight {
                from {
                    bottom: -200px;
                    right: -200px;
                }
                to {
                    bottom: 0;
                    right: 0;
                }
            }
            .arrow-right {
                background: $dark;
                clip-path: polygon(100% 0, 0% 100%, 100% 100%);
                height: 250px;
                width: 250px;
                position: absolute;
                right: 0px;
                bottom: 0px;
                animation: slideRight 1s;
            }

            .arrow-right span {
                color: $white;
                width: 100px;
                height: 100px;
                position: absolute;
                top: 50%;
                left: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                p {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                }
            }
        }

        .mobile-logo {
            display: none;
            margin: 80px 0;
            img {
                width: 100%;
                height: auto;
                max-width: 150px;
            }
            @include sm {
                display: block;
            }
        }
    }
}

// for mobile view
#home {
    .mobile-intro {
        display: none;
        @include sm {
            display: block;
        }
        .home_slider_wrapper_mobile {
            width: 100%;
            position: relative;
            height: 190px;
            z-index: 0;
            overflow: hidden;

            img {
                // min-width: 110%;
                min-height: 100%;
            }
            .control-dots {
                bottom: 10%;
                li {
                    width: 5px;
                    height: 5px;
                }
            }
        }
        .headline-Wrapper-mobile {
            min-height: 40vh;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .text {
                padding: 40px;
                .mobile-logo {
                    margin: 0%;
                    padding: 20px 0;
                }
                h1 {
                    font-size: 20px;
                }
                p {
                    margin-top: 20px;
                    font-size: 14px;
                }
            }
        }
        .home_social_wrapper_mobile {
            padding-top: 50px;
            .contact {
                h5 {
                    font-size: 16px;
                    padding: 5px 0;
                }
                .phone {
                    margin: 10px 0;
                    a {
                        color: $white;
                    }
                }
                .mail {
                    @extend .phone;
                }
            }
            .social {
                padding-top: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
